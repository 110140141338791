import React from 'react';
import { ReactComponent as twitterIcon } from 'assets/images/icons/X.svg';
import { ReactComponent as linkedinIcon } from 'assets/images/icons/linkedin.svg';
import { ReactComponent as ThreeWhiteLines } from 'assets/images/icons/three_lines.svg';
import { ReactComponent as ThreeBlackLines } from 'assets/images/icons/black_lines.svg';
import { ReactComponent as BlackChromeIcon } from 'assets/images/icons/black-chrome.svg';
import { ReactComponent as WhiteChromeIcon } from 'assets/images/icons/google.svg';
import { ReactComponent as YellowChromeIcon } from 'assets/images/icons/yellow_chrome.svg';
import { ReactComponent as WhiteLeftButton } from 'assets/images/icons/blank_arrow_left.svg';
import { ReactComponent as WhiteRightButton } from 'assets/images/icons/blank_right_arrow.svg';
import { ReactComponent as YellowRightArrow } from 'assets/images/icons/yellow_arrow.svg';

const dictWithIcons = {
    twitterIcon,
    linkedinIcon,
    ThreeWhiteLines,
    ThreeBlackLines,
    BlackChromeIcon,
    WhiteChromeIcon,
    YellowChromeIcon,
    WhiteLeftButton,
    WhiteRightButton,
    YellowRightArrow,
};

const Icon = ({ type, size = 16, style, fill = 'currentColor' }) => {
    const Element = dictWithIcons[type];
    if (!Element) {
        console.error('Icon type not found:', type);
        return null;
    }
    return <Element width={size} height={size} fill={fill} style={style} />;
};

export default Icon;

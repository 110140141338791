import styled from 'styled-components';
import 'assets/fonts.css';
import elipseFirst from 'assets/images/elipse_middle.svg';

const TwoBoxLeft = ({ heading, text, main_photo, color, small_text }) => {
    return (
        <MainContainer>
            <LeftBlock
                style={{
                    backgroundImage: `url(${elipseFirst})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: color,
                }}
            >
                <Image src={main_photo} />
            </LeftBlock>
            <RightBlock>
                <TextBlock>
                    <SmallText>{small_text}</SmallText>
                    <Text>{heading}</Text>
                    <InfoText>{text}</InfoText>
                </TextBlock>
            </RightBlock>
        </MainContainer>
    );
};

export default TwoBoxLeft;

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 720px;

    @media (max-width: 1200px) {
        height: auto;
        flex-direction: column;
        width: 100%;
    }
`;

const LeftBlock = styled.div`
    position: relative;
    width: 50%;
    height: 720px;
    background-color: #d8eff9;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 0;

    @media (max-width: 1200px) {
        height: 417px;
        order: 1;
        width: 100%;
    }
`;

const SmallText = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 16px;
    color: #737373;
    margin-bottom: 32px;
`;

const RightBlock = styled.div`
    background-color: white;
    width: 50%;
    height: 720px;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;

    @media (max-width: 1200px) {
        order: 0;
        height: 417px;
        width: 100%;
    }
`;

const TextBlock = styled.div`
    @media (max-width: 1200px) {
        padding: 20px;
    }
`;

const Text = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 48px;
    width: 489px;

    @media (max-width: 1200px) {
        font-size: 32px;
        width: auto;
    }
`;

const InfoText = styled.div`
    font-family: 'Inter-light';
    width: 489px;
    font-size: 18px;
    color: #474747;
    font-weight: 400;
    margin-top: 16px;

    @media (max-width: 1200px) {
        font-size: 16px;
        width: auto;
    }
`;

const Image = styled.img`
    max-width: 100%;
    width: auto;
    height: auto;

    @media (max-width: 1024px) {
        max-width: 90%;
        max-height: 90%;
    }
`;

import styled from 'styled-components';
import BigCircle from 'assets/images/big_circle.svg';
import SmallPicture from 'assets/images/footer_mobile.svg';
import Icon from './Icon';

const PurpleFooter = () => {
    return (
        <MainContainer>
            <Image src={BigCircle} />
            <Content>
                <Heading>
                    Every minute counts,
                    <span className="conditional-space">&nbsp;</span>
                    <div style={{ display: 'inline-block' }}>
                        <span className="highlight">know its worth</span>.
                    </div>
                </Heading>
                <YellowButtonStyled
                    onClick={() => {
                        window.location.href =
                            'https://chromewebstore.google.com/detail/spry/kioknbjoaogjjfmbbiafkagepdhhfjje';
                    }}
                >
                    <Icon
                        type="BlackChromeIcon"
                        size="20"
                        style={{ marginRight: '10px' }}
                    />
                    Add to Chrome &nbsp;
                    <span className="thinText"> It's free</span>
                </YellowButtonStyled>
                <CostText>
                    Know the cost of every meeting{' '}
                    <StyledSpan>with Spry Plan</StyledSpan>
                </CostText>
            </Content>
        </MainContainer>
    );
};

export default PurpleFooter;

const MainContainer = styled.div`
    position: relative;
    background-color: #140027;
    width: 100%;
    height: 438px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 2;

    @media (max-width: 1200px) {
        background-image: url(${SmallPicture});
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 3;
    width: 100%;
`;

const StyledSpan = styled.span`
    @media (max-width: 700px) {
        display: none;
    }
`;

const Heading = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    width: 100%;
    line-height: 62px;
    font-weight: 500;
    font-size: 46px;
    padding: 0 20px;
    overflow: hidden;
    text-align: center;

    .conditional-space {
        display: none;
    }

    .highlight {
        color: #ffcf40;
        display: inline; /* Ensure it remains inline */
    }

    @media (min-width: 734px) {
        .conditional-space {
            display: inline;
        }
    }

    @media (max-width: 733px) {
        white-space: normal;
        text-align: start;
    }

    @media (min-width: 734px) and (max-width: 834px) {
        text-align: center;
    }

    @media (max-width: 1200px) {
        font-size: 46px;
        line-height: 62px;
        //text-align: start;
        margin-left: 16px;
    }
`;

const Image = styled.img`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    max-width: 100%;
    height: auto;

    @media (max-width: 1200px) {
        display: none;
    }
`;

const YellowButtonStyled = styled.button`
    font-family: 'Inter';
    margin-top: 56px;
    font-weight: 600;
    background-color: #ffcf40;
    width: 227px;
    height: 40px;
    padding: 10px 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: black;
    .thinText {
        font-weight: 400;
    }
    &:hover {
        background-color: #f3c332;
    }

    @media (max-width: 1200px) {
        width: 90%;
    }
`;

const CostText = styled.span`
    font-family: 'SharpGrotesk-Light';
    margin-top: 32px;
    color: white;
    font-weight: 400;
    font-size: 20px;

    @media (max-width: 1200px) {
        font-size: 20px;
        line-height: 26px;
    }
`;

const ImageLogIn = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

import styled from 'styled-components';
import 'assets/fonts.css';
import CheckPoint from 'assets/images/icons/circle_point.svg';
import PurpleButton from 'assets/images/icons/purple_button.svg';
import { useState, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';
import { useUser } from './technical/UserContext';
import { ref, get, query } from 'firebase/database';
import { db } from 'firebaseconfig';
import CurrentPlanBadge from 'assets/images/badge.svg';

const API_KEY = 'AIzaSyDkMnfM4g5pgzXZpZVzEZxCCmiAH2rayTk';

const PricingContainer = () => {
    const [loadingFirst, setLoadingFirst] = useState(false);
    const [loadingSecond, setLoadingSecond] = useState(false);
    const [userPlan, setUserPlan] = useState(null);
    const [canceled, setCanceled] = useState(null);
    const [date, setDate] = useState(null);
    const { user } = useUser();

    const fetchToken = async () => {
        const url = `https://securetoken.googleapis.com/v1/token?key=${API_KEY}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };

        const data = new URLSearchParams({
            grant_type: 'refresh_token',
            refresh_token: user.stsTokenManager.refreshToken,
        });

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: data,
            });
            return await response.json();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const fetchUserPlan = async () => {
            if (user) {
                const res = await fetchToken();
                console.log(res);
                const response = await fetch('https://spry-api-extension-dot-spry-398908.appspot.com/profile', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        token: res.access_token,
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                } else {
                    const responseData = await response.json();
                    if (responseData.subscription_status === "canceled") {
                        setCanceled(true);
                        const date = new Date(responseData.next_payment_date);
                        const day = date.getDate();
                        const month = date.getMonth() + 1;
                        const year = date.getFullYear();
                        const formattedDate = `${day}.${month}.${year}`;
                        setDate(formattedDate);
                        
                    }   
                    setUserPlan(responseData.plan);
                }
            }
        };

        fetchUserPlan();
    }, [user]);

    const checkout = async (price) => {
        if (!user) {
            window.location.href =
                'https://chromewebstore.google.com/detail/spry/kioknbjoaogjjfmbbiafkagepdhhfjje?hl=ru&authuser=0';
            return;
        }
        let plan;
        if (price === 5) {
            plan = 'Business';
        } else {
            plan = 'Business Lifetime';
        }
        const res = await fetchToken();
        const paymentInfo = {
            plan: plan,
        };
        console.log(res.access_token)
        try {
            const response = await fetch('https://spry-api-extension-dot-spry-398908.appspot.com/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: res.access_token,
                },
                body: JSON.stringify(paymentInfo),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                const responseData = await response.json();
                if (responseData.url) {
                    window.location.assign(responseData.url);
                }
            }
        } catch (err) {
            console.error('Error in fetching or parsing JSON:', err);
        }
    };

    const handleClick = () => {
        window.location.href =
            'https://chromewebstore.google.com/detail/spry/kioknbjoaogjjfmbbiafkagepdhhfjje?hl=ru&authuser=0';
        return;
    };

    return (
        <MainContainer>
            <hr style={{ position: 'absolute', top: '20', color: '#E3E3E5' }} />
            <MainHeader>
                Say goodbye to unproductive meetings!
                <br />
                Join now for smarter planning and big savings.
            </MainHeader>
            <PricingBox>
                <PricingCard>
                    <UpperText>
                        Starter
                        {userPlan === 'Starter' && (
                            <img src={CurrentPlanBadge} />
                        )}
                    </UpperText>
                    <MainText>Free</MainText>
                    <ThinText>
                        Unlock essential meeting tools for small teams at no
                        cost.
                    </ThinText>
                    {!user && (
                        <Button backgroundColor="#1A1A1A" onClick={handleClick}>
                            <span
                                style={{
                                    fontWeight: '600',
                                    color: 'white',
                                }}
                            >
                                Get started
                            </span>
                            &nbsp;&nbsp;{' '}
                            <span style={{ color: 'white' }}>It's free</span>
                        </Button>
                    )}
                    <ListOfFeatures>
                        <Item>
                            <img src={CheckPoint} />
                            <CheckPointText>Up to 5 users</CheckPointText>
                        </Item>
                        <Item>
                            <img src={CheckPoint} />
                            <CheckPointText>
                                Import emails from calendar
                            </CheckPointText>
                        </Item>
                    </ListOfFeatures>
                </PricingCard>
                <PricingCard>
                    <StyledImage src={PurpleButton} />
                    <UpperText>
                        Business
                        {canceled &&
                        <div style={{backgroundColor: "#E3E3E5", borderRadius: "6px", fontSize: "12px", padding: "3px 7px", marginTop: "-5px"}}>
                            Active until {date}
                        </div>
                        }
                        {!canceled && userPlan === 'Business' && (
                            <img src={CurrentPlanBadge} />
                        )}
                    </UpperText>
                    <MainText>$5 / month</MainText>
                    <ThinText>
                        Maximize efficiency, no limits on participants or
                        possibilities.
                    </ThinText>
                    {userPlan != 'Business Lifetime' && userPlan != "Business" && !canceled && (
                        <Button
                            backgroundColor="#FFCF40"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setLoadingFirst(!loadingFirst);
                                checkout(5);
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <BeatLoader
                                    loading={loadingFirst}
                                    size={10}
                                    color="black"
                                />
                                {!user
                                    ? !loadingFirst && (
                                        <span style={{ marginLeft: '8px' }}>
                                            <span style={{ fontWeight: 600 }}>
                                                Get started
                                            </span>
                                            &nbsp; It's free
                                        </span>
                                    )
                                    : userPlan === 'Starter'
                                    ? !loadingFirst && (
                                        <span style={{ marginLeft: '8px' }}>
                                            <span style={{ fontWeight: 600 }}>
                                                Get business
                                            </span>
                                        </span>
                                    )
                                    : null}
                            </div>
                        </Button>
                    )}

                    {canceled && (
                        <Button
                            backgroundColor="#FFCF40"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setLoadingFirst(!loadingFirst);
                                checkout(5);
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <BeatLoader
                                    loading={loadingFirst}
                                    size={10}
                                    color="black"
                                />
                                <span style={{ marginLeft: '8px' }}>
                                    <span style={{ fontWeight: 600 }}>
                                        Renew business
                                    </span>
                                </span> 
                            </div>
                        </Button>
                    )}

                    <ListOfFeatures>
                        <Item>
                            <img src={CheckPoint} />
                            <CheckPointText>Unlimited users</CheckPointText>
                        </Item>
                        <Item>
                            <img src={CheckPoint} />
                            <CheckPointText>
                                Import emails from calendar
                            </CheckPointText>
                        </Item>
                        <Item>
                            <img src={CheckPoint} />
                            <CheckPointText>Priority support</CheckPointText>
                        </Item>
                        <Item>
                            <img src={CheckPoint} />
                            <CheckPointText>
                                Early access to new features
                            </CheckPointText>
                        </Item>
                    </ListOfFeatures>
                </PricingCard>
                <PricingCard>
                    <UpperText>
                        Business Lifetime
                        {userPlan === 'Business Lifetime' && (
                            <img src={CurrentPlanBadge} />
                        )}
                    </UpperText>
                    <MainText>$25 / one time</MainText>
                    <ThinText>
                        Invest in the future of meetings with unlimited access,
                        forever.
                    </ThinText>
                    {userPlan != 'Business Lifetime' && (
                        <Button
                            backgroundColor="#1A1A1A"
                            onClick={() => {
                                checkout(25);
                                setLoadingSecond(!loadingSecond);
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <BeatLoader
                                    loading={loadingSecond}
                                    size={10}
                                    color="white"
                                />
                                {!user
                                    ? !loadingSecond && (
                                          <span
                                              style={{
                                                  marginLeft: '8px',
                                                  color: 'white',
                                              }}
                                          >
                                              <span
                                                  style={{
                                                      fontWeight: 600,
                                                      color: 'white',
                                                  }}
                                              >
                                                  Get started
                                              </span>
                                              &nbsp; It's free
                                          </span>
                                      )
                                    : userPlan != 'Business lifetime'
                                      ? !loadingSecond && (
                                            <span style={{ marginLeft: '8px' }}>
                                                <span
                                                    style={{
                                                        fontWeight: 600,
                                                        color: 'white',
                                                    }}
                                                >
                                                    Get business lifetime
                                                </span>
                                            </span>
                                        )
                                      : null}
                            </div>
                        </Button>
                    )}

                    <ListOfFeatures>
                        <Item>
                            <img src={CheckPoint} />
                            <CheckPointText>Unlimited users</CheckPointText>
                        </Item>
                        <Item>
                            <img src={CheckPoint} />
                            <CheckPointText>
                                Import emails from calendar
                            </CheckPointText>
                        </Item>
                        <Item>
                            <img src={CheckPoint} />
                            <CheckPointText>Priority support</CheckPointText>
                        </Item>
                        <Item>
                            <img src={CheckPoint} />
                            <CheckPointText>
                                Early access to new features
                            </CheckPointText>
                        </Item>
                    </ListOfFeatures>
                </PricingCard>
            </PricingBox>
            <TaxesText>
                *Taxes may be included regarding to your location
            </TaxesText>
        </MainContainer>
    );
};

export default PricingContainer;

const MainContainer = styled.div`
    background-color: white;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
`;

const MainHeader = styled.div`
    margin-top: 96px;
    margin-bottom: 80px;
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: #1a1a1a;
    text-align: center;
    @media (max-width: 1200px) {
        text-align: start;
        padding: 20px;
    }
`;

const StyledImage = styled.img`
    position: absolute;
    top: -27px;
    left: 33%;
`;

const Button = styled.button`
    padding: 7px 15px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 32px;
    background-color: ${(props) => props.backgroundColor};
    width: 304px;
    margin: 2.5rem;
    margin-bottom: 22px;
    @media (max-width: 1200px) {
        margin: 1.5rem;
        width: 279px;
        height: 40px;
    }
`;

const PricingBox = styled.div`
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin-bottom: 56px;

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
    }
`;

const MainText = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    margin: 2.5rem;
    @media (max-width: 1200px) {
        margin: 1.5rem;
    }
`;

const PricingCard = styled.div`
    position: relative;
    width: 24rem;
    height: 506px;
    border: 1px solid #cacacc;
    border-radius: 12px;

    @media (max-width: 1200px) {
        width: 90%;
        margin-bottom: 40px;
    }
`;

const MiddleCard = styled.div`
    position: relative;
    width: 24rem;
    height: 506px;

    //&::before {
    //    content: "";
    //    position: absolute;
    //    inset: 0;
    //    border: 1px solid transparent;
    //    border-radius: 12px;
    //    background: linear-gradient(to right, #FFBC05, #9867E4);
    //    -webkit-mask:
    //            linear-gradient(#fff 0 0) padding-box,
    //            linear-gradient(#fff 0 0);
    //    -webkit-mask-composite: xor;
    //    mask-composite: exclude;
    //}

    @media (max-width: 1200px) {
        width: 90%;
        margin-bottom: 40px;
    }
`;

const UpperText = styled.div`
    margin: 2.5rem;
    font-family: 'SharpGrotesk-Medium20';
    color: #474747;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    gap: 12px;

    @media (max-width: 1200px) {
        margin: 1.5rem;
    }
`;

const TaxesText = styled.div`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #737373;
    margin-bottom: 96px;

    @media (max-width: 1200px) {
        display: none;
    }
`;

const ThinText = styled.div`
    color: #737373;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 2.5rem;
    @media (max-width: 1200px) {
        margin: 1.5rem;
    }
`;

const ListOfFeatures = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    gap: 15px;
    @media (max-width: 1200px) {
        margin: 1.5rem;
    }
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

const CheckPointText = styled.div`
    color: #474747;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;

import TwoBoxSection from 'components/TwoBoxSection';
import FirstBlockPhoto from 'assets/images/1.webp';
import TwoBoxLeft from 'components/TwoBoxLeft';
import SecondBlockPhoto from 'assets/images/2.webp';
import ThirdBlockPhoto from 'assets/images/3.webp';
import FourthBlockPhoto from 'assets/images/4.webp';
import FifthBlockPhoto from 'assets/images/5.webp';
import React from 'react';

const HowItWorks = () => {
    return (
        <div id="how_it_works">
            <TwoBoxSection
                small_text="Add users"
                color={'#CEF8DD'}
                svgFileName="image1"
                positionX="0"
                positionY="20px"
                heading="Input Details Manually"
                text="Easily enter participant details such as their email
             and wage (yearly, monthly, weekly, or hourly)."
                main_photo={FirstBlockPhoto}
            />
            <TwoBoxLeft
                small_text="Add users"
                heading="Automatic Detection from Calendar"
                text="
            Leverage our intelligent system to auto-detect and add participants
             from your recent meetings, streamlining the setup process."
                main_photo={SecondBlockPhoto}
            />
            <TwoBoxSection
                small_text="Operation"
                color={'#F9E7D6'}
                svgFileName="image2"
                positionX="0px"
                positionY="0px"
                heading="Seamless Information Management"
                text="
            Conveniently store all participant details at your fingertips,
             right in the extension bar at the top of your screen."
                main_photo={ThirdBlockPhoto}
            />
            <TwoBoxLeft
                small_text="Operation"
                color={'#EDE8FA'}
                heading="Customize for Clarity"
                text="
            Adapt the presentation of your team's information to fit your preferences.
             Select the most intuitive and clear layout for viewing meeting costs,
              enhancing your ability to make informed decisions."
                main_photo={FourthBlockPhoto}
            />
            <TwoBoxSection
                small_text="Analyzing"
                color={'#F2F5D6'}
                main_photo={FifthBlockPhoto}
                heading="Daily Team Cost Insights"
                text="
            Instantly access detailed cost analysis for each team meeting.
             This everyday tool is designed to transform your approach
             to meeting planning, aligning it more closely with your team's financial objectives."
                svgFileName="image2"
                positionX="0px"
                positionY="0px"
            />
        </div>
    );
};

export default HowItWorks;

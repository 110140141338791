import styled from 'styled-components';
import 'assets/fonts.css';
import React from 'react';
import image1 from 'assets/images/elipse_first.svg';
import image2 from 'assets/images/elipse_second.svg';

const svgImages = {
    image1: image1,
    image2: image2,
};

const TwoBoxSection = React.forwardRef(
    (
        {
            svgFileName,
            color,
            positionX = 'center',
            positionY = 'center',
            heading,
            text,
            main_photo,
            small_text,
        },
        ref
    ) => {
        const svgFile = svgImages[svgFileName];

        return (
            <MainContainer ref={ref}>
                <RightBlock>
                    <Content>
                        <SmallText>{small_text}</SmallText>
                        <Text>{heading}</Text>
                        <InfoText>{text}</InfoText>
                    </Content>
                </RightBlock>
                <LeftBlock
                    style={{
                        backgroundColor: color,
                        backgroundImage: `url(${svgFile})`,
                        backgroundPosition: `${positionX} ${positionY}`,
                        backgroundSize: 'cover',
                    }}
                >
                    <ImageContainer>
                        <Image src={main_photo} />
                    </ImageContainer>
                </LeftBlock>
            </MainContainer>
        );
    }
);

export default TwoBoxSection;

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 720px;

    @media (max-width: 1200px) {
        height: auto;
        flex-direction: column;
    }
`;

const RightBlock = styled.div`
    width: 50%;
    height: 720px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) {
        width: 100%;
        height: 417px;
    }
`;

const Content = styled.div`
    @media (max-width: 1200px) {
        padding: 20px;
    }
`;

const Text = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 48px;
    width: 489px;

    @media (max-width: 1200px) {
        font-size: 32px;
        width: auto;
    }
`;

const InfoText = styled.div`
    font-family: 'Inter-light';
    width: 489px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 16px;
    color: #474747;

    @media (max-width: 1200px) {
        font-size: 16px;
        width: auto;
    }
`;

const SmallText = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 16px;
    color: #737373;
    margin-bottom: 32px;
`;

const LeftBlock = styled.div`
    width: 50%;
    height: 720px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) {
        width: 100%;
        height: auto;
    }
`;

const ImageContainer = styled.div`
    max-width: 80%;
    height: auto;

    @media (max-width: 1200px) {
        max-width: 90%;
    }
`;

const Image = styled.img`
    width: 100%;
    height: auto;
    max-height: 90%;
`;

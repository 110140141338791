import styled from 'styled-components';
import React, { useState } from 'react';
import HorizontalLine from 'assets/images/line_horizontal.svg';
import 'assets/fonts.css';

const ArrowIcon = ({ isOpen }) => (
    <svg
        width="13"
        height="8"
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d={isOpen ? 'M1 7L6.5 1L12 7' : 'M1 1L6.5 7L12 1'}
            stroke="black"
        />
    </svg>
);

const DropdownItem = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleItem = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Line src={HorizontalLine} />
            <DropMenuText onClick={toggleItem} style={{ cursor: 'pointer' }}>
                {title}
                <ArrowIcon isOpen={isOpen} />
            </DropMenuText>
            {isOpen && <DropDownText>{children}</DropDownText>}
        </>
    );
};

const Line = styled.img`
    width: 100%;
`;

const DropdownMenu = () => {
    return (
        <DropDown>
            <DropdownItem title="Will the meeting cost data be shared with all participants?">
                No, this information is only visible to you. We provide visuals
                of the meeting cost and participants' wages exclusively in your
                calendar.
            </DropdownItem>
            <DropdownItem title="If I add someone's email, will they be notified?">
                No, we do not send any notifications to the individuals whose
                emails you add to your profile.
            </DropdownItem>
            <DropdownItem title="What if I don’t have access to my colleagues' salary information?">
                You can use websites like{' '}
                <a
                    href="https://www.glassdoor.com/index.htm"
                    target="_blank"
                    rel="noreferrer"
                >
                    Glassdoor
                </a>{' '}
                or{' '}
                <a
                    href="https://www.levels.fyi"
                    target="_blank"
                    rel="noreferrer"
                >
                    Levels.fyi
                </a>{' '}
                to estimate salary information. You can then input these
                estimated figures to calculate the meeting cost for your calls.
            </DropdownItem>

            <Line src={HorizontalLine} />
        </DropDown>
    );
};

const FaqPrising = () => {
    return (
        <MainContainer>
            <Heading>Frequently asked questions</Heading>
            <DropdownMenu />
        </MainContainer>
    );
};

export default FaqPrising;

const MainContainer = styled.div`
    width: 100%;
    background-color: #f6f6f8;
    display: flex;
    border-bottom: 1px solid #e3e3e5;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Heading = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    width: 90%;
    max-width: 407px;
    line-height: 40px;
    height: auto;
    font-weight: 500;
    font-size: 28px;
    margin-top: 96px;
`;

const DropMenuText = styled.div`
    font-family: 'SharpGrotesk-Light';
    color: #1a1a1a;
    padding: 28px 16px;
    display: flex;
    font-weight: 1000;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 1200px) {
        padding: 20px 10px;
        line-height: 32px;
        font-size: 20px;
    }
`;

const DropDownText = styled.div`
    font-weight: 400;
    font-family: 'Inter-light';
    color: #474747;
    margin: 24px 16px;
    width: calc(100% - 32px);
    font-size: 18px;
    box-sizing: border-box;
    word-wrap: break-word;

    @media (max-width: 1200px) {
        font-size: 16px;
        padding: 24px 10px;
    }
`;

const DropDown = styled.div`
    margin-top: 84px;
    display: flex;
    flex-direction: column;
    margin-bottom: 96px;
    width: 100%;
    max-width: 828px;

    @media (max-width: 1200px) {
        max-width: 100%;
    }
`;

import styled from 'styled-components';
import { getDatabase, ref, push, set } from '@firebase/database';
import { useState } from 'react';
import Icon from '../Icon';

const Footer = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const saveEmailToRealtimeDatabase = async () => {
        console.log('Attempting to save email:', email);
        try {
            const db = getDatabase();
            const emailRef = ref(db, 'emails');
            const newEmailRef = push(emailRef);
            await set(newEmailRef, {
                email: email,
                timestamp: Date.now(),
            });
            console.log('Email saved with key: ', newEmailRef.key);
        } catch (e) {
            console.error('Error adding document: ', e);
            alert('Error adding document: ' + e);
        }
        setEmail('');
    };

    return (
        <>
            <MainContainer>
                <EmailDiv>
                    <EmailText>
                        Stay tuned to our updates and special offers
                    </EmailText>
                    <InputButtonContainer>
                        <EmailInput
                            type="email"
                            placeholder="Your email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <SubmitButton onClick={saveEmailToRealtimeDatabase}>
                            Subscribe
                        </SubmitButton>
                    </InputButtonContainer>
                </EmailDiv>
                <ContactSection>
                    <ContactUs>Contact us</ContactUs>
                    <ThinText>support@spryplan.com</ThinText>
                </ContactSection>
                <SocialNetworksConatiner>
                    <Text>Find us in socials</Text>
                    <ImagesContainer>
                        <ButtonOverlay
                            onClick={() => {
                                window.open('https://x.com/SpryPlan', '_blank');
                            }}
                        >
                            <Icon type="twitterIcon" size="26" />
                        </ButtonOverlay>
                        <ButtonOverlay
                            onClick={() => {
                                window.open(
                                    'https://www.linkedin.com/company/101826573',
                                    '_blank'
                                );
                            }}
                        >
                            <Icon type="linkedinIcon" size="26" />
                        </ButtonOverlay>
                    </ImagesContainer>
                </SocialNetworksConatiner>
            </MainContainer>
            <TermsofuseSection>
                <SpryText>© 2024 Spry Plan</SpryText>
                <OneSection>
                    <InfoText href="/privacy_policy">Privacy policy</InfoText>
                    <InfoText href="/terms_of_use">Terms of use</InfoText>
                </OneSection>
            </TermsofuseSection>
        </>
    );
};

export default Footer;

const MainContainer = styled.div`
    border-bottom: 1px solid #e3e3e5;
    height: 242px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 1200px) {
        height: 100%;
        max-width: 100%;
    }
`;

const EmailText = styled.div`
    font-size: 20px;
    color: black;
    font-family: 'SharpGrotesk-Light';

    @media (max-width: 1200px) {
        margin-left: 10px;
        margin-right: 10px;
    }
`;

const ButtonOverlay = styled.div`
    border: 1px solid #cacacc;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    &:hover {
        border: 1px solid #474747;
    }
`;

const EmailInput = styled.input`
    width: 334px;
    height: 40px;
    border: solid #919191;
    border-radius: 6px;
    padding: 4px 8px 4px 10px;
    font-size: 14px;
    margin-right: 16px;
    font-family: 'Inter';
    box-sizing: border-box;
    color: #919191;

    @media (max-width: 1200px) {
        width: 100%;
        margin-right: 0;
    }
`;

const ContactSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    @media (max-width: 1200px) {
        width: 90%;
    }
`;

const SubmitButton = styled.button`
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    height: 40px;
    width: 106px;

    &:hover {
        background-color: #474747;
    }
`;

const EmailDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 80px;
    padding-left: 112px;
    gap: 20px;

    @media (max-width: 1200px) {
        align-items: center;
        padding-left: 0;
        justify-content: center;
    }
`;

const ContactUs = styled.div`
    color: #1a1a1a;
    font-family: 'SharpGrotesk-Light';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    @media (max-width: 1200px) {
        margin-left: 1rem;
        margin-top: 3rem;
        margin-bottom: 0;
    }
`;

const ThinText = styled.div`
    color: #737373;
    font-family: 'SharpGrotesk-Light';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1200px) {
        margin-left: 1rem;
    }
`;

const InputButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 1200px) {
        width: 90%;
        flex-direction: column;
        align-items: stretch;

        ${EmailInput}, ${SubmitButton} {
            width: 100%;
            margin-right: 0;
        }
    }
`;

const SocialNetworksConatiner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 80px;
    margin-right: 112px;

    @media (max-width: 1200px) {
        margin-right: 0;
        margin-top: 56px;
        margin-bottom: 72px;
        padding-left: 25px;
        align-items: flex-start;
    }
`;

const Text = styled.div`
    color: #1a1a1a;
    font-family: 'SharpGrotesk-Light';
    font-size: 20px;
`;

const ImagesContainer = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: row;
    gap: 16px;
    @media (max-width: 1200px) {
        justify-content: start;
    }
`;

const Image = styled.button`
    margin-right: 16px;
    cursor: pointer;
    width: 40px;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #cacacc;
    background-color: transparent;
    &:hover {
        border: 1px solid #474747;
    }
`;

const TermsofuseSection = styled.div`
    display: flex;
    height: 68px;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 20px;
    }
`;

const SpryText = styled.div`
    color: #474747;
    font-weight: 400;
    font-family: 'Inter';
    font-size: 14px;
    margin-left: 112px;

    @media (max-width: 1200px) {
        margin: 0;
        height: 100%;
        padding-top: 24px;
        padding-bottom: 24px;
        order: 2;
    }
`;

const InfoText = styled.a`
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    font-family: 'Inter';
    color: #474747;
    &:hover {
        color: #737373;
    }
`;

const OneSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 216px;
    margin-right: 112px;

    @media (max-width: 1200px) {
        width: 100%;
        justify-content: space-evenly;
        margin: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid #e3e3e5;
    }
`;

import FaqPrising from 'components/FAQ_Pricing';
import React, { useEffect } from 'react';
import PricingConatiner from 'components/PricingContainer';
import { useUser } from '../components/technical/UserContext';

const Pricing = () => {
    return (
        <>
            <PricingConatiner />
            <FaqPrising />
        </>
    );
};

export default Pricing;

import styled from 'styled-components';
import 'assets/fonts.css';
import Image from 'assets/images/final_elipse.svg';
import RightSideElipse from 'assets/images/right_side_elipse.svg';
import LeftSideElipse from 'assets/images/left_side_elipse.svg';
import DownElipse from 'assets/images/down_elipse.svg';
import UpperLine from 'assets/images/upper_line.svg';
import MainFlow from 'assets/images/main_flow.svg';
import Icon from './Icon';

const Main = () => {
    return (
        <MainContainer>
            <MainImage src={RightSideElipse} />
            <LeftMainImage src={LeftSideElipse} />
            <DownElipseImage src={DownElipse} />
            <Line src={UpperLine} />
            <ImageForMobile src={Image} />
            <ContentWrapper>
                <Sprycenter>
                    Spry Plan is a personal meeting cost calculator
                </Sprycenter>
                <SpryMotto>
                    Eliminate Unnecessary Meetings. <br />
                    Improve{' '}
                    <yellow className="highlight">Meeting Culture</yellow>.
                </SpryMotto>
                <YellowButtonStyled
                    onClick={() => {
                        window.location.href =
                            'https://chromewebstore.google.com/detail/spry/kioknbjoaogjjfmbbiafkagepdhhfjje';
                    }}
                >
                    <Icon
                        type="BlackChromeIcon"
                        size="20"
                        style={{ marginRight: '10px' }}
                    />
                    Add to Chrome&nbsp;{' '}
                    <span className="thinText"> It's free</span>
                </YellowButtonStyled>
                <CostText>Know the cost of every meeting</CostText>
                <ImageContainer>
                    <Im src={MainFlow} />
                </ImageContainer>
                {/*<YellowBlock/>*/}
            </ContentWrapper>
        </MainContainer>
    );
};

export default Main;

const MainContainer = styled.div`
    position: relative;
    background:
        no-repeat center center / cover,
        #140027;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: auto;

    @media (max-width: 1200px) {
        width: 100%;
        margin-top: 0;
        max-width: 100%;
    }
`;

const Im = styled.img`
    @media (max-width: 1200px) {
        width: 787px;
        height: 381px;
    }

    @media (max-width: 800px) {
        display: none;
    }
`;

const MainImage = styled.img`
    position: absolute;
    right: 0;
    top: 70px;
    z-index: 2;

    @media (max-width: 1200px) {
        display: none;
    }
`;

const LeftMainImage = styled.img`
    position: absolute;
    left: -10px;
    top: 430px;

    @media (max-width: 1200px) {
        display: none;
    }
`;

const Line = styled.img`
    position: absolute;
    left: 73px;
    top: 180px;
    z-index: 1;
    @media (max-width: 1200px) {
        display: none;
    }

    @media (min-width: 1920px) {
        left: 1120px;
        display: none;
    }
`;

const RectangleRight = styled.img`
    position: absolute;
    left: 770px;
    top: 620px;
    z-index: 0;
    @media (max-width: 1200px) {
        display: none;
    }
    @media (min-width: 1920px) {
        left: 1250px;
        top: 590px;
    }
`;

const DownElipseImage = styled.img`
    position: absolute;
    left: 920px;
    bottom: 0;
    z-index: 0;
    @media (max-width: 1200px) {
        display: none;
    }

    @media (min-width: 1920px) {
        left: 1420px;
    }
`;

const RectangleLeft = styled.img`
    position: absolute;
    left: 200px;
    top: 650px;
    z-index: 0;
    @media (max-width: 1200px) {
        display: none;
    }

    @media (min-width: 1920px) {
        left: 660px;
        top: 630px;
    }
`;

const ContentWrapper = styled.div`
    margin-top: 73px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;

    @media (max-width: 1200px) {
        margin-top: 40px;
    }
`;

const Sprycenter = styled.div`
    color: #ffcf40;
    font-size: 20px;
    font-weight: 400;
    font-family: 'SharpGrotesk-Light';
    @media (max-width: 1200px) {
        margin: 61px 16px 48px;
    }
    @media (max-width: 734px) {
        margin-top: -40px;
    }
`;

const SpryMotto = styled.span`
    z-index: 2;
    width: 1261px;
    font-size: 46px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 32px;
    height: auto;
    word-wrap: break-word;
    font-style: normal;
    white-space: pre-wrap;
    font-family: 'SharpGrotesk-Medium20';
    line-height: 62px;
    text-align: center;

    @media (max-width: 800px) {
        width: auto;
        font-size: 49px;
        text-align: start;
        margin: 16px;
    }
    .highlight {
        color: #ffcf40;
        @media (min-width: 430px) {
            white-space: nowrap;
        }
    }
`;

const YellowButtonStyled = styled.button`
    margin-top: 72px;
    font-weight: 600;
    background-color: #ffcf40;
    width: 227px;
    height: 40px;
    padding: 10px 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
    font-weight: 600;
    color: var(--neutral-80);

    .thinText {
        font-weight: 400;
    }

    @media (max-width: 1200px) {
        margin-top: 48px;
        width: 90%;
    }

    &:hover {
        background-color: #f3c332;
    }
`;

const ImageLogIn = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const CostText = styled.span`
    margin-top: 32px;
    color: white;
    z-index: 2;
    font-weight: 400;
    font-size: 20px;
    font-family: 'SharpGrotesk-Light';
    @media (max-width: 1200px) {
        margin-bottom: 70px;
    }
`;

const ImageContainer = styled.div`
    width: 100%;
    position: relative;
    justify-content: center;
    margin-top: 64px;
    align-items: center;
    display: flex;
    height: 511px;
    margin-bottom: 82px;

    @media (max-width: 1200px) {
        margin-top: 0;
    }

    @media (max-width: 800px) {
        display: none;
    }
`;

const ResponsiveImage = styled.img`
    width: auto;
    height: auto;
    position: absolute;

    @media (max-width: 1200px) {
        position: static;
        width: 80%;
        max-width: 200px;
        margin-bottom: 20px;
    }
`;

const FirstImage = styled(ResponsiveImage)`
    width: 403px;
    height: 373px;
    top: 47px;
    left: 172px;

    @media (max-width: 1200px) {
        display: none;
        width: 80%;
        max-width: 200px;
        height: auto;
        position: static;
        margin-bottom: 20px;
    }

    @media (min-width: 1920px) {
        top: 20px;
        left: 632px;
    }
`;

const SecondImage = styled(ResponsiveImage)`
    width: 322px;
    height: 317px;
    left: 478px;

    @media (max-width: 1200px) {
        width: 80%;
        display: none;
        max-width: 200px;
        height: auto;
        position: static;
        margin-bottom: 20px;
    }

    @media (min-width: 1920px) {
        top: -45px;
        left: 952px;
    }
`;

const ThirdImage = styled(ResponsiveImage)`
    width: 281px;
    height: 410px;
    top: 89px;
    left: 729px;

    @media (max-width: 1200px) {
        display: none;
        width: 80%;
        max-width: 200px;
        height: auto;
        position: static;
        margin-bottom: 20px;
    }

    @media (min-width: 1920px) {
        top: 50px;
        left: 1222px;
    }
`;

const FourthImage = styled(ResponsiveImage)`
    width: 394px;
    height: 222px;
    top: 37px;
    left: 946px;

    @media (max-width: 1200px) {
        display: none;
        width: 80%;
        max-width: 200px;
        height: auto;
        position: static;
        margin-bottom: 20px;
    }

    @media (min-width: 1920px) {
        top: 10px;
        left: 1422px;
    }
`;

const CombinedImage = styled.img`
    margin-bottom: 20px;
    display: none;

    @media (max-width: 1200px) {
        display: block;
        width: 90%;
        height: 100%;
        z-index: 2;
    }
`;

const ImageForMobile = styled.img`
    display: none;

    @media (max-width: 1200px) {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: auto;
        max-width: 100%;
        height: auto;
    }
`;

import { initializeApp } from '@firebase/app';
import { getDatabase } from '@firebase/database';
import { getAuth } from '@firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyDkMnfM4g5pgzXZpZVzEZxCCmiAH2rayTk',
    authDomain: 'spry-398908.firebaseapp.com',
    databaseURL:
        'https://spry-398908-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'spry-398908',
    storageBucket: 'spry-398908.appspot.com',
    messagingSenderId: '786136769301',
    appId: '1:786136769301:web:e41f8aa8e2fb7422f2bf1c',
};

const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
const auth = getAuth(app);

export { db, auth };

import styled from 'styled-components';
import horizontalLine from 'assets/images/line_horizontal.svg';
import verticalLine from 'assets/images/line.svg';
import 'assets/fonts.css';
import Arrow from 'assets/images/icons/arrow_link.svg';

const Metrics = () => {
    return (
        <MainContainer>
            <MainHeading>Why are we building it?</MainHeading>
            <ContentContainer>
                <Section1>
                    <BigNumber>$37B</BigNumber>
                    <Info>
                        approximate cost that companies spend on meetings
                        annually.
                    </Info>
                    <Link>
                        <StyledLink
                            href="https://hbr.org/2018/02/how-to-fix-the-most-soul-crushing-meetings"
                            target="_blank"
                        >
                            Harvard Business Review
                        </StyledLink>
                        <StyledImage src={Arrow} />
                    </Link>
                </Section1>
                <Section1>
                    <BigNumber>252%</BigNumber>
                    <Info>
                        increase in meeting time for the typical employee since
                        February 2020
                    </Info>
                    <Link>
                        <StyledLink
                            href="https://www.microsoft.com/en-us/worklab/work-trend-index/great-expectations-making-hybrid-work-work"
                            target="_blank"
                        >
                            Microsoft report
                        </StyledLink>
                        <StyledImage src={Arrow} />
                    </Link>
                </Section1>
                <Section1>
                    <BigNumber>$25,000</BigNumber>
                    <Info>
                        costs of the meeting time per employee, annually.
                    </Info>
                    <Link>
                        <StyledLink
                            href="https://www.nytimes.com/2023/04/07/business/office-meetings-time.html"
                            target="_blank"
                        >
                            NY Times
                        </StyledLink>
                        <StyledImage src={Arrow} />
                    </Link>
                </Section1>
                <Section1>
                    <BigNumber>18hrs</BigNumber>
                    <Info>
                        approximate time that office workers spend per week in
                        meetings.
                    </Info>
                    <Link>
                        <StyledLink
                            href="https://www.nytimes.com/2023/04/07/business/office-meetings-time.html"
                            target="_blank"
                        >
                            NY Times
                        </StyledLink>
                        <StyledImage src={Arrow} />
                    </Link>
                </Section1>
                <Section1>
                    <BigNumber>71%</BigNumber>
                    <Info>
                        of managers viewed meetings as costly and unproductive
                        even before the pandemic
                    </Info>
                    <Link>
                        <StyledLink
                            href="https://sloanreview.mit.edu/article/the-surprising-impact-of-meeting-free-days/"
                            target="_blank"
                        >
                            CBS News
                        </StyledLink>
                        <StyledImage src={Arrow} />
                    </Link>
                </Section1>
                <Section1>
                    <BigNumber>65%</BigNumber>
                    <Info>
                        of Sr. Managers say meetings keep them from completing
                        their own work.
                    </Info>
                    <Link>
                        <StyledLink
                            href="https://hbr.org/2017/07/stop-the-meeting-madness"
                            target="_blank"
                        >
                            Harvard Business Review
                        </StyledLink>
                        <StyledImage src={Arrow} />
                    </Link>
                </Section1>
            </ContentContainer>
        </MainContainer>
    );
};

export default Metrics;

const MainContainer = styled.div`
    background-color: #f6f6f8;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const MainHeading = styled.h1`
    font-family: 'SharpGrotesk-Medium20';
    margin-top: 96px;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 56px;

    @media (max-width: 1200px) {
        font-size: 28px;
        margin-top: 48px;
        margin-bottom: 28px;
    }
`;

const StyledImage = styled.img`
    padding-top: 5px;
    margin-left: 5px;
    width: 16px;
`;

const StyledLink = styled.a`
    text-decoration: none;
    color: #146ef6;
    &:hover {
        color: #0050c3;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    max-width: 1280px;
    margin: auto;
    position: relative;
    margin-bottom: 96px;
    padding-top: 1px;
    padding-bottom: 1px;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        background: url(${horizontalLine}) no-repeat center center;
        background-size: cover;
        top: calc(50%);
    }

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;

        &:after {
            display: none;
        }
    }
`;

const Section1 = styled.div`
    width: 404.67px;
    height: 231px;
    padding: 40px 48px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    &:not(:nth-child(3n)):after {
        content: '';
        position: absolute;
        top: 0;
        right: -8px;
        width: 1px;
        background: url(${verticalLine}) no-repeat center center;
        background-size: cover;
    }

    &:nth-child(-n + 3):not(:nth-child(3n)):after {
        bottom: -33px;
    }

    &:nth-child(n + 4):not(:nth-child(3n)):after {
        bottom: 0;
    }

    @media (max-width: 1200px) {
        width: 90%;
        height: auto;
        padding: 20px;
        border-bottom: 1px solid #dcdcdc;

        &:after {
            display: none;
        }
        &:last-child {
            border-bottom: none;
        }

        &:nth-of-type(n + 4) {
            display: none;
            border-bottom: none;
        }
    }
`;

const BigNumber = styled.div`
    font-weight: 500;
    font-size: 48px;
    text-align: center;
    margin-bottom: 8px;
    font-family: 'SharpGrotesk-Medium20';

    @media (max-width: 1200px) {
        font-size: 36px;
    }
`;

const Info = styled.div`
    font-family: 'Inter-light';
    font-weight: 300;
    color: #474747;
    font-size: 16px;
    text-align: center;
    justify-content: center;
`;

const Link = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 40px;
`;

import { useEffect } from 'react';
import Main from 'components/Main';
import Metrics from 'components/Metrics';
import FaqPrising from 'components/FAQ_Pricing';
import PurpleFooter from 'components/PurpleFooter';
import React from 'react';
import HowItWorks from './HowItWorks';
import { useLocation } from 'react-router-dom';

const MainPage = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <>
            <Main />
            <Metrics />
            <HowItWorks />
            <FaqPrising />
            <PurpleFooter />
        </>
    );
};

export default MainPage;

import styled from 'styled-components';

const FormattedText = ({ info }) => {
    const renderText = (textItem, nested = false) => {
        return (
            <Paragraph key={textItem.index} nested={nested}>
                {textItem.showIndex && !nested
                    ? `${textItem.index}. `
                    : textItem.showIndex && nested
                      ? `${textItem.index} `
                      : ''}
                {textItem.text}
                {Array.isArray(textItem.subTexts) &&
                    textItem.subTexts.map((subText) =>
                        renderText(subText, true)
                    )}
            </Paragraph>
        );
    };

    return (
        <>
            <Container>
                <Header>
                    {info.showIndex && info.index}
                    {info.showIndex && '. '}
                    {info.header}
                </Header>
                {info.texts.map((text) => renderText(text))}
            </Container>
        </>
    );
};

export default FormattedText;

const Container = styled.div`
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Paragraph = styled.p`
    color: #474747;
    font-family: 'SharpGrotesk-Medium20';
    font-size: 18px;
    line-height: 28px;
    margin-left: ${(props) => (props.nested ? '20px' : '0')};
    padding-left: ${(props) => (props.nested ? '10px' : '0')};
    text-indent: ${(props) => (props.nested ? '-10px' : '0')};
`;

const Header = styled.h1`
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
`;

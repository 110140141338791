import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from 'components/technical/UserContext';
import { getDatabase, ref, update, get } from '@firebase/database';
import Image from 'assets/images/spry_text.svg';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SuccessPayment() {
    const query = useQuery();
    const sessionId = query.get('verification_token');
    const { user } = useUser();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(true);
    const [userPlan, setUserPlan] = useState(null);
    const [email, setEmail] = useState('');

    useEffect(() => {
        const verifySession = async () => {
            try {
                const response = await fetch(
                    `https://spry-api-extension-dot-spry-398908.appspot.com/payment/verify?user_id=${sessionId}`
                );
                const data = await response.json();
                console.log('Verification response:', data);
                if (data.success) {
                    setSuccess(true);
                    setEmail(data.email);
                    setUserPlan(data.plan);
                } else {
                    console.error('Failed to verify session:', data.error);
                    if (data.error === 'Session already verified') {
                        return;
                    }
                    setError('Failed to verify session');
                }
            } catch (err) {
                console.error('Error during session verification:', err);
            } finally {
                setLoading(false);
            }
        };

        if (sessionId) {
            verifySession().then((r) => {});
        } else {
            console.error('No session ID or token provided');
            setError('No session ID or token provided');
            setLoading(false);
        }
    }, [sessionId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            {success ? (
                <>
                    <MainContainer>
                        <StyledImg src={Image} />
                        <BothTexts>
                            <Text>
                                {userPlan} plan is now active!
                            </Text>
                            <ThinText>
                                Thank you for your purchase! We’ve sent an email
                                confirmation to {email}.
                            </ThinText>
                        </BothTexts>
                        <ThinText
                            onClick={() => {
                                window.location.href = 'https://spryplan.com/';
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Feel free to close this tab
                        </ThinText>
                    </MainContainer>
                </>
            ) : (
                <h1>Payment verification failed</h1>
            )}
        </div>
    );
}

export default SuccessPayment;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
`;

const BothTexts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`;

const StyledImg = styled.img`
    padding-top: 5%;
`;

const Text = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    color: black;
`;

const ThinText = styled.div`
    padding-bottom: 5%;
    color: #474747;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
`;

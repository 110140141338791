import React from 'react';
import styled from 'styled-components';
import FormattedText from '../components/FormattedText';

const policySections = [
    {
        index: '1',
        showIndex: 'true',
        header: 'Introduction',
        texts: [
            {
                index: '1.1',
                showIndex: false,
                text:
                    'Thank you for using Spry. This Privacy Policy is designed to help\n' +
                    'you understand how we collect, use, disclose, and safeguard your\n' +
                    'information when you use our extension, which is distributed through\n' +
                    'the Chrome Web Store.',
            },
            {
                index: '1.2',
                showIndex: false,
                text:
                    'By using the Extension, you consent to the practices described in\n' +
                    'this Privacy Policy.',
            },
        ],
    },
    {
        index: '2',
        showIndex: 'true',
        header: 'Information We Collect',
        texts: [
            {
                index: '(a)',
                showIndex: false,
                text:
                    'User-Provided Information: We may collect information that\n' +
                    'you provide directly when you use the extension, such as information\n' +
                    'about your google account, user wages inputed from popup.',
            },
            {
                index: '(b)',
                showIndex: false,
                text:
                    'Automatically Collected Information: When you use Spry, we\n' +
                    'may automatically collect certain information about your device,\n' +
                    'browser and account. This may include your google calendar events,\n' +
                    'their attendants.',
            },
        ],
    },
    {
        index: '3',
        showIndex: 'true',
        header: 'How We Use Your Information',
        texts: [
            {
                index: '',
                showIndex: false,
                text: 'We may use the information we collect for various purposes, including:',
                subTexts: [
                    {
                        showIndex: true,
                        index: '(a)',
                        text: 'Calculate total price for each event',
                    },
                    {
                        showIndex: true,
                        index: '(b)',
                        text: 'Distinguish users and store their personal data',
                    },
                    {
                        showIndex: true,
                        index: '(c)',
                        text: 'Detect attendants from calendar events',
                    },
                    {
                        showIndex: true,
                        index: '(d)',
                        text: 'Analyze usage patterns and optimize users time',
                    },
                ],
            },
        ],
    },
    {
        index: '4',
        showIndex: 'true',
        header: 'Information Sharing and Disclosure',
        texts: [
            {
                index: '(a)',
                showIndex: false,
                text:
                    "Our app's use of information received from Google Calendar API will\n" +
                    'adhere to the Google API Services User Data Policy, including the Limited Use requirements. We only use event attendee\n' +
                    'emails to simplify adding them to your boards, and this data is not\n' +
                    'shared with any third parties.',
            },
        ],
    },
    {
        index: '5',
        showIndex: 'true',
        header: 'Security',
        texts: [
            {
                index: '(a)',
                showIndex: false,
                text:
                    'We take reasonable measures to protect your information from\n' +
                    'unauthorized access, disclosure, alteration, or destruction. However,\n' +
                    'no data transmission over the internet or stored on a server can be\n' +
                    'guaranteed to be completely secure.',
            },
        ],
    },
    {
        index: '6',
        showIndex: 'true',
        header: 'Changes to this Privacy Policy',
        texts: [
            {
                index: '(a)',
                showIndex: false,
                text:
                    'We may update this Privacy Policy from time to time to reflect changes\n' +
                    'in our practices or for other operational, legal, or regulatory\n' +
                    'reasons. We will notify you of any material changes through the Spry\n' +
                    'or via other means.',
            },
        ],
    },
    {
        index: '7',
        showIndex: 'true',
        header: 'Accessing the Privacy Policy',
        texts: [
            {
                index: '(a)',
                showIndex: false,
                text: (
                    <span>
                        You can access our Privacy Policy by following this
                        link:{' '}
                        <a
                            style={{ color: '#474747' }}
                            href="https://spryplan.com/privacy_policy"
                        >
                            Privacy Policy
                        </a>
                        .
                    </span>
                ),
            },
        ],
    },
    {
        index: '8',
        showIndex: 'true',
        header: 'Contact Us',
        texts: [
            {
                index: '(a)',
                showIndex: false,
                text: (
                    <span>
                        If you have any questions, concerns, or requests related
                        to this Privacy Policy, please contact us at{' '}
                        <a
                            style={{ color: '#474747' }}
                            href="mailto:hello@spryplan.com"
                        >
                            hello@spryplan.com
                        </a>
                        .
                    </span>
                ),
            },
        ],
    },
];

const PrivacyPolicy = () => {
    return (
        <>
            <MainContainer>
                <Heading>Privacy Policy</Heading>
                {policySections.map((section) => (
                    <FormattedText key={section.index} info={section} />
                ))}
            </MainContainer>
            <hr style={{ marginTop: '120px', color: '#E3E3E5' }} />
        </>
    );
};

export default PrivacyPolicy;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    padding-left: 15%;
    min-width: 70%;
    max-width: 70%;
`;

const Heading = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 32px;
    width: 100%;
`;

import styled from 'styled-components';
import Logo from 'assets/images/logo_image.svg';
import Confetti from 'react-confetti';
import { useWindowSize } from '@uidotdev/usehooks';
import { useNavigate } from 'react-router-dom';
import Arrow from 'assets/images/icons/Arrow.svg';

const SuccessLogin = () => {
    const navigate = useNavigate();
    const { width, height } = useWindowSize();

    const handleCloseClick = () => {
        navigate('/');
    };

    return (
        <>
            <MainContainer>
                <YellowArrow src={Arrow} />
                <Confetti
                    width={width}
                    height={height}
                    recycle={false}
                    numberOfPieces={400}
                />
                <TextImageContainer>
                    <LogoImage
                        src={Logo}
                        onClick={() => {
                            window.location.href = 'https://spryplan.com';
                        }}
                    />
                </TextImageContainer>
                <MainContent>
                    <BigText>Welcome on board! 🚀</BigText>
                    <ThinText>
                        You're all set to simplify your schedule. Click on the
                        extension icon to start your journey towards a
                        meeting-free day. Let's reclaim your time together!
                    </ThinText>
                </MainContent>
                <BottomText onClick={handleCloseClick}>
                    Feel free to close this tab
                </BottomText>
            </MainContainer>
        </>
    );
};

export default SuccessLogin;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const YellowArrow = styled.img`
    position: fixed;
    top: 10vh;
    left: 83vw;
`;

const Text = styled.div`
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1a1a1a;
    width: 107px;
`;

const TextImageContainer = styled.div`
    display: flex;
    max-width: 100%;
    flex-direction: row;
    gap: 16px;
    margin-top: 11.375rem;
    margin-bottom: 9rem;
`;

const LogoImage = styled.img`
    cursor: pointer;
`;

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const BigText = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    color: #000000;
    margin-bottom: 32px;
    text-align: center;
`;

const ThinText = styled.div`
    color: #474747;
    width: 591px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 15rem;
`;

const BottomText = styled.div`
    color: #737373;
    font-family: 'Inter';
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
`;
